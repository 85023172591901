.rdt_TableHeadRow .rdt_TableCol {
  font-weight: bold;
}

.rdt_Table,
.rdt_TableRow {
  background-color: transparent !important;
}

.rdt_TableHeadRow {
  background-color: #fafaf9 !important;
}
